<div class="app-news">
  <app-banner></app-banner>

  <div class="title container">
    <h2>墨小动态</h2>
    <h3>MOXIAODYNAMIC</h3>
  </div>

  <main>
    <div class="container">
      <div class="row" routerLink="/newDetail/0">
        <div class="col-sm-3">
          <img src="/assets/news_img1.png" alt="墨小动态">
        </div>
        <div class="col-sm-9">
          <h2>喜大普奔，墨小科技成立</h2>
          <p>我公司于2018年10月18日注册成立，注册资金500万，致力于以聚合支付为核心，利用大
            数据、AI技术、身份识别技术，为各大中小型企业，以及个体商户提供支付的一站式整体解
            决方案。</p>
          <h6>2020.12.31</h6>
        </div>
      </div>
      <div class="row" routerLink="/newDetail/1">
        <div class="col-sm-3">
          <img src="/assets/news_img2.png" alt="墨小动态">
        </div>
        <div class="col-sm-9">
          <h2>开拓垂直邻域，墨小数据旗下子公司有舍成立</h2>
          <p>为进一步适应市场，将聚合支付融入生活，墨小科技于2020年底成立分公司有舍，专注于
            房屋租赁，解决房东和租客之间，实际生活中产生的各种难题。</p>
          <h6>2020.12.31</h6>
        </div>
      </div>
    </div>
  </main>

  <section class="container">
    <div class="row">
      <h2 class="col">历史版本更新</h2>
    </div>
    <div class="row justify-content-between">
      <div class="col-sm-4">
        <a routerLink="/history/0">行业全周期收款解决方案</a>
      </div>
      <div class="col-sm-4">
        <a routerLink="/history/1">墨小数据信贷员1.8.3版发布</a>
      </div>
      <div class="col-sm-4">
        <a routerLink="/history/2">行业全周期收款解决方案</a>
      </div>
    </div>
  </section>
</div>
