<div class="public-form">
  <div class="arrow">
    <img src="/assets/downArrow.png" alt="箭头">
  </div>
  <div class="title row text-center">
    <h2 class="col-12">企业申请试用</h2>
    <h3 class="col-12">APPLY FOR PROBATION</h3>
  </div>
  <div class="main container">
    <form>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="name">姓名</label>
          <input type="text" placeholder="请输入姓名" class="form-control" id="name" name="name" [(ngModel)]="name">
        </div>
        <div class="form-group col-md-4">
          <label for="phone">电话</label>
          <input type="text" placeholder="请输入电话" class="form-control" id="phone" name="phone" maxlength="11"
                 [(ngModel)]="phone">
        </div>
        <div class="form-group col-md-4">
          <label for="enterpriseName">企业名称</label>
          <input type="text" placeholder="请输入企业名称" class="form-control" id="enterpriseName" name="enterpriseName"
                 [(ngModel)]="enterpriseName">
        </div>
      </div>
      <div class="form-row align-items-center">
        <div class="form-group col-md-10">
          <label>手机验证码</label>
          <div class="d-flex align-items-center code">
            <input maxlength="6" placeholder="请输入手机验证码" class="col-8" name="verificationCode"
                   [(ngModel)]="verificationCode">
            <span class="col-4" (click)="code()">{{codeText}}</span>
          </div>
        </div>
        <div class="col-md-2">
          <button type="submit" class="btn btn-primary" [disabled]='!name || !phone || !verificationCode' (click)="save()">提交</button>
        </div>
      </div>
    </form>
    <div class="error">
      <div *ngIf="error">{{error}}</div>
    </div>
  </div>
  <div class="footer container">
    <div class="row">
      <div class="col-md-5"><img src="/assets/index_img23.png" class="img_1">如有疑问请拨打 <span>400-676-0009</span></div>
      <div class="col-md-7">个人申请试用, 直接关注“<span>墨小数据</span>”微信公众号, 登录试用 <img src="/assets/index_img24.png" class="img_2">
      </div>
    </div>
  </div>
</div>
