<div class="app-index">
  <div class="banner">
    <h2>行业全周期收款解决方案</h2>
    <p>提升行业经营效率, 引领行业数据化转型</p>
    <img src="/assets/banner.jpg" alt="banner">
  </div>

  <div class="container" style="padding-top: 1.2rem">
    <img src="/assets/security-new_img1.png" alt="解决方案" style="max-width: 100%">
  </div>

  <div class="scenario">
    <header class="title">
      <h2>服务场景</h2>
      <h3>SERVICE SCENARIOS</h3>
    </header>
    <main class="container">
      <div class="row">
        <div class="col-4 col-lg-3" routerLink="/serviceSector/0">
          <img src="/assets/index_img1.png" alt="服务场景">
          <h4>物业管理公司</h4>
        </div>
        <div class="col-4 col-lg-3" routerLink="/serviceSector/1">
          <img src="/assets/index_img2.png" alt="服务场景">
          <h4>教育培训行业</h4>
        </div>
        <div class="col-4 col-lg-3" routerLink="/serviceSector/2">
          <img src="/assets/index_img3.png" alt="服务场景">
          <h4>金融保险公司</h4>
        </div>
        <div class="col-4 col-lg-3" routerLink="/serviceSector/3">
          <img src="/assets/index_img4.png" alt="服务场景">
          <h4>中介服务公司</h4>
        </div>
        <div class="col-4 col-lg-3" routerLink="/serviceSector/4">
          <img src="/assets/index_img5.png" alt="服务场景">
          <h4>租赁行业公司</h4>
        </div>
        <div class="col-4 col-lg-3" routerLink="/serviceSector/5">
          <img src="/assets/index_img6.png" alt="服务场景">
          <h4>代理记账公司</h4>
        </div>
        <div class="col-4 col-lg-3" routerLink="/serviceSector/6">
          <img src="/assets/index_img7.png" alt="服务场景">
          <h4>第三方缴费</h4>
        </div>
        <div class="col-4 col-lg-3">
          <img src="/assets/index_img8.png" alt="服务场景">
          <h4>更多行业</h4>
        </div>
      </div>
    </main>
  </div>

  <div class="advantage">
    <header class="title">
      <h2>服务优势</h2>
      <h3>SERVICE ADVANTAGE</h3>
    </header>
    <main class="container">
      <div class="row">
        <div class="col-md-4" routerLink="/advantage/0">
          <img src="/assets/index_img9.png" alt="服务优势">
          <h4>平台服务</h4>
          <span>→</span>
        </div>
        <div class="col-md-4" routerLink="/advantage/1">
          <img src="/assets/index_img10.png" alt="服务优势">
          <h4>API对接</h4>
          <span>→</span>
        </div>
        <div class="col-md-4" routerLink="/advantage/2">
          <img src="/assets/index_img11.png" alt="服务优势">
          <h4>系统定制</h4>
          <span>→</span>
        </div>
      </div>
    </main>
  </div>

  <div class="object">
    <header class="title">
      <h2>服务对象</h2>
      <h3>SERVICE OBJECT</h3>
    </header>
    <main class="container">
      <div class="row">
        <div class="col-6 col-sm-4 col-md-3" routerLink="/serviceObject/0">
          <div>
            <div class="img">
              <img src="/assets/index_img13.png" alt="服务对象">
            </div>
            <h4>房东</h4>
            <button class="d-none d-md-block">查看详情</button>
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-3" routerLink="/serviceObject/1">
          <div>
            <div class="img">
              <img src="/assets/index_img14.png" alt="服务对象">
            </div>
            <h4>教育运营经理</h4>
            <button class="d-none d-md-block">查看详情</button>
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-3" routerLink="/serviceObject/2">
          <div>
            <div class="img">
              <img src="/assets/index_img15.png" alt="服务对象">
            </div>
            <h4>信贷专员</h4>
            <button class="d-none d-md-block">查看详情</button>
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-3" routerLink="/serviceObject/3">
          <div>
            <div class="img">
              <img src="/assets/index_img16.png" alt="服务对象">
            </div>
            <h4>中介客户经理</h4>
            <button class="d-none d-md-block">查看详情</button>
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-3" routerLink="/serviceObject/4">
          <div>
            <div class="img">
              <img src="/assets/index_img17.png" alt="服务对象">
            </div>
            <h4>打车软件开发</h4>
            <button class="d-none d-md-block">查看详情</button>
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-3" routerLink="/serviceObject/5">
          <div>
            <div class="img">
              <img src="/assets/index_img18.png" alt="服务对象">
            </div>
            <h4>健身房会员经理</h4>
            <button class="d-none d-md-block">查看详情</button>
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-3" routerLink="/serviceObject/6">
          <div>
            <div class="img">
              <img src="/assets/index_img19.png" alt="服务对象">
            </div>
            <h4>招商经理</h4>
            <button class="d-none d-md-block">查看详情</button>
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <div>
            <div class="img">
              <img src="/assets/index_img20.png" alt="服务对象">
            </div>
            <h4>更多</h4>
            <button class="d-none d-md-block">查看详情</button>
          </div>
        </div>
      </div>
    </main>
  </div>

  <div class="partner">
    <header class="title">
      <h2>超级合伙人</h2>
      <h3>SUPER PARTNER</h3>
    </header>
    <main class="container">
      <div class="row">
        <div class="col-sm-6">
          <section>
            <h4>合伙流程</h4>
            <p>有识之士, 互创共赢 <span>→</span></p>
          </section>
        </div>
        <div class="col-sm-6">
          <img src="/assets/index_img21.png" alt="超级合伙人">
        </div>
        <div class="col-sm-6">
          <img src="/assets/index_img22.png" alt="超级合伙人">
        </div>
        <div class="col-sm-6">
          <section>
            <h4>政府扶持</h4>
            <p>一站式培训, 创业无难处<span>→</span></p>
          </section>
        </div>
      </div>
    </main>
  </div>
</div>

