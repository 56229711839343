<div class="app-service-sector">
  <app-banner></app-banner>

  <div class="title container">
    <h2>服务领域</h2>
    <h3>SERVICE AREA</h3>
  </div>

  <div class="container">
    <div class="row">
      <section class="col-md-3">
        <ul>
          <li [className]="active === 0 ? 'on' : ''" (click)="onChange(0)">物业管理公司</li>
          <li [className]="active === 1 ? 'on' : ''" (click)="onChange(1)">教育培训企业</li>
          <li [className]="active === 2 ? 'on' : ''" (click)="onChange(2)">金融保险公司</li>
          <li [className]="active === 3 ? 'on' : ''" (click)="onChange(3)">中介服务公司</li>
          <li [className]="active === 4 ? 'on' : ''" (click)="onChange(4)">租赁行业公司</li>
          <li [className]="active === 5 ? 'on' : ''" (click)="onChange(5)">代理记账公司</li>
          <li [className]="active === 6 ? 'on' : ''" (click)="onChange(6)">第三方缴费</li>
          <li>更多行业 资讯客服</li>
        </ul>
      </section>

      <main class="col-md-9">
        <div *ngIf="active === 0">
          <h2>物业管理公司</h2>
          <h3>适合功能:</h3>
          <p>小额免密扣款、分期定额扣款、预售额度扣款</p>
          <h3>使用方法:</h3>
          <p>1、小额免密扣款: 自动收取业主的会员费及其他费用，并且支持固定周期续费。</p>
          <p>2、分期定额扣款: 以年度、季度、月度等周期，自动收取业主的物业费。</p>
          <p>3、预售额度扣款: 自主结算业主的水电费、燃气费、. 上门维修费用，从业主账户扣取。</p>
        </div>
        <div *ngIf="active === 1">
          <h2>教育培训企业</h2>
          <h3>适合功能:</h3>
          <p>分期定额扣款、预授额度扣款</p>
          <h3>使用方法:</h3>
          <p>1、分期定额扣款: 帮助教育行业实现自动收取学费功能，支持以任意学期或课时为单位，定时收取。</p>
          <p>2、预售额度扣款: 定时结算学生的学杂费、伙食费，自动划扣收取。</p>
        </div>
        <div *ngIf="active === 2">
          <h2>金融保险公司</h2>
          <h3>适合功能:</h3>
          <p>分期定额扣款</p>
          <h3>使用方法:</h3>
          <p>1、分期定额扣款: 按照金融、保险产品协议规定，定时定期从客户账户扣取费用。</p>
        </div>
        <div *ngIf="active === 3">
          <h2>中介服务公司</h2>
          <h3>适合功能:</h3>
          <p>分期定额扣款、预授额度扣款</p>
          <h3>使用方法:</h3>
          <p>1、分期定额扣款: 适合持续时间周期较长的中介服务类型，可按照约定时收取客户费用。</p>
          <p>2、预售额度扣款: 适合短期或者单次的中介服务类型，完成中介服务以后可以按照约定，定时收取客户费用。</p>
        </div>
        <div *ngIf="active === 4">
          <h2>租赁行业公司</h2>
          <h3>适合功能:</h3>
          <p>分期定额扣款</p>
          <h3>使用方法:</h3>
          <p>1、分期定额扣款: 租赁期间，按照合同预定时间、约定金额，自动从客户账户扣取费用。</p>
        </div>
        <div *ngIf="active === 5">
          <h2>代理记账公司</h2>
          <h3>适合功能:</h3>
          <p>分期定额扣款、预售额度扣款</p>
          <h3>使用方法:</h3>
          <p>1、分期定额扣款: 定时从记账公司收取服费用，保证服务质量的连贯性。</p>
          <p>2、预售额度扣款: 对于客户的其他增值服务，自主结算，从客户的账户中扣取。</p>
        </div>
        <div *ngIf="active === 6">
          <h2>第三方缴费</h2>
          <h3>适合功能:</h3>
          <p>分期定额扣款、预售额度扣款</p>
          <h3>使用方法:</h3>
          <p>1、分期定额扣款: 定时从记账公司收取服费用，保证服务质量的连贯性。</p>
          <p>2、预售额度扣款: 对于客户的其他增值服务，自主结算，从客户的账户中扣取。</p>
        </div>
      </main>
    </div>
  </div>
</div>
