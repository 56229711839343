<footer class="public-footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-8 d-flex flex-wrap">
                <dl class="col">
                    <dt>墨小数据</dt>
                    <dd>
                        客服电话： 400-676-0009
                    </dd>
                    <dd>
                        邮箱地址： 2724775618@qq.com
                    </dd>
                    <dd>
                        工作时间：周一周五 早上9：00 下午6：00
                    </dd>
                </dl>
            </div>
            <div class="col-sm-4">
                <h3 class="col-12">关注我们</h3>
                <ul class="col-12 d-flex img">
                  <li>
                    <img src="/assets/footer_img1.png" alt="在线客服">
                    <p>在线客服</p>
                  </li>
                    <li>
                        <img src="/assets/footer_img2.jpg" alt="公众号">
                        <p>公众号</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="copyright col d-flex">
              <div class="col text-center">
                <p class="icp" (click)="icpLink()">沪ICP备19004203号-1 &nbsp;</p>
                | Copyright © 2020 - 2022 All Rights Reserved. 上海墨小数据 版权所有
              </div>
            </div>
        </div>
    </div>
</footer>
