<div class="app-about">
  <app-banner></app-banner>

  <div class="nav">
    <div class="container">
      <div class="row">
        <div class="col">
          <div [className]="active === 0 ? 'on' : ''" (click)="onChange(0)">公司发展历程</div>
          <div [className]="active === 1 ? 'on' : ''" (click)="onChange(1)">市场前景</div>
        </div>
      </div>
    </div>
  </div>

  <main *ngIf="active === 0">
    <div class="introduce container">
      <div class="row">
        <div class="col-md-6">
          <h2>公司介绍</h2>
          <h3>MOXIAO SERVICE</h3>
          <p>
            上海墨小科技数据有限公司成立于2018年10月18日，以自动扣款为开发核心，专注于一体化信息服务搭建， 为广大中小
            型企业提供方便、快捷的客户身份识别、电子签约、协议支付等，全流程线上-站式服务。
          </p>
        </div>
        <div class="col-md-6 ">
          <img src="/assets/about_img1.png" alt="">
        </div>
      </div>
    </div>

    <div class="title container">
      <h2>发展历程</h2>
      <h3>DEVELOPMENT HISTORY</h3>
    </div>

    <section class="courseList">
      <div class="container">
        <div class="row">
          <ul class="col-md-6 offset-md-6">
            <li>
              <h4>2018年12月</h4>
              <p>墨小数据武汉运营研发中心正式成立；</p>
            </li>
            <li>
              <h4>2019年3月</h4>
              <p>墨小数据APP上线，实现移动端进件系统与后台客户管理系统联动；</p>
            </li>
            <li>
              <h4>2019年9月</h4>
              <p>公司启动业务升级转型，以技术驱动为中心，配比研发人员总数达到50%以
                上，具备了全面、稳健及技术驱动的风险管理能力，能够有效管理和降低各类
                风险，并切实履行各项监管规定。</p>
            </li>
            <li>
              <h4>2020年8月</h4>
              <p>墨小科技将AI算法、生物识别、数据库三大核心技术融入SaaS矩阵，为各类
                消费打造聚合支付场景，助力数字化转型。</p>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <div class="course container">
      <ol class="row">
        <li class="col-sm-6">
          <p>至此，经历2年多的发展，通过多维度销售渠道互补，墨小科技已能满足来自不同行业及不同规模的客户需求。
          </p>
          <p>在防范欺诈风险版块，通过多维度组合身份验证，层层过滤客户欺诈风险。对于运维领域，降低了经营成本，大幅提升代协议支付务审核时效，节省人力支出。在安全角度，实行多重身份，双向认证，保障数据传输及存储安全。
          </p>
        </li>
        <li class="col-sm-6">
          <p>未来，墨小数据将一直保持其核心价值观，始终致力于解决客户的需求，研发出更好产品，为社会创造更多价值。</p>
          <p>抱变革, 探索未知，才能定义更美好的未来。憧憬明天，今日笃行。在今后的发展中，墨小数据必将乘风破浪，实现更加宏伟的目标。</p>
        </li>
      </ol>
    </div>
  </main>

  <main *ngIf="active === 1">
    <div class="title container">
      <h2>市场前景</h2>
      <h3>MARKET PROSPECT</h3>
    </div>

    <div class="prospect">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h2>支付市场</h2>
            <p>
              随着时代的发展
              ，移动智能无处不自。从衣食住行，再到办公和社交，无论企业还是个人，只要涉及到消费场景，使用智能移动支付，已悄然成为人们离不开的主要方式。市场规模也在短短两年半的时间里，从2017第一季度的0.6万亿元扩大到了2019第二季度的8.0万亿元，增幅超过12倍。
            </p>
          </div>
          <div class="col-md-6">
            <img src="/assets/about_img3.png" alt="市场前景">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <img src="/assets/about_img4.png" alt="市场前景">
          </div>
          <div class="col-md-6">
            <h2>墨小优势</h2>
            <p>
              把握趋势，潜心开发。墨小数据结合聚合支付的特点，打造出了全新的快捷支付方式，为广大用户提供了多渠道，无障碍的消费功能，解决了日常生活中，包含餐饮、租赁、信贷等各种不同场景的需求，大大缩短人工和时间成本。同时，墨小数据也凭借着这一优势，完成了自有版图扩张，现有app注册用户达到5万人次，日活用户达到8000人次。 </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <h2>智能移动支付</h2>
            <p>目前，智能移动支付市场仍旧保持高增长态势，预计2020第四季度将达到9.6万亿，市场专项领域需求也会呈几何倍数增长，诞生更多的机遇。</p>
            <p>
              为了进一步响应市场，从2020年开始，墨小数据将向多场景渗透，聚焦垂直行业，例如地铁、公交、停车、校园等多个垂直领域，不断扩大场景适用性。定位各个一二线城市，从不同领域发展，促进聚合支付更好的融入到日常生活之中，为民众提供便利。并且始终保持不做资金结算的原则，为消费者提供安全有保障的支付通道，避免支付行为的风险。</p>
            <p>赢得市场的认可，是墨小数据一贯坚持的生存之道。上下一心，同舟共济，墨小数据始终保持平稳增长的趋势，预计在2022年底，app注册用户达到20万，日活用户达到5万人次，扩展到更宏伟的蓝图。</p>
          </div>
          <div class="col-md-6">
            <img src="/assets/about_img3.png" alt="市场前景">
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
