<div class="app-cooperation-home">
  <app-banner></app-banner>

  <div class="title container">
    <h2>超级合伙人</h2>
    <h3>SUPER PARTNER</h3>
  </div>

  <main>
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <h2>合作流程</h2>
          <p>
            01机遇合作意向 <span>02洽谈参观考察</span><br/>
            03携手明确合作 <span>04合作签订合同</span><br/>
            05蓄势店铺规划 <span>06协助施I监督</span><br/>
            ….</p>
          <a routerLink="/cooperation/0" class="link_1">
            查看详情
            <span>→</span>
          </a>
        </div>
        <div class="col-md-6 offset-md-1 d-flex justify-content-end align-items-end img img_1">
          <img src="/assets/cooperation_img1.png" alt="超级合伙人">
        </div>
      </div>
      <div class="row">
        <div class="col-md-6  d-flex align-items-end img img_2">
          <img src="/assets/cooperation_img2.png" alt="超级合伙人">
        </div>
        <div class="col-md-5 offset-md-1 right">
          <h2>政策扶持</h2>
          <p>
            01规划优势 <span>02营销优势</span><br/>
            03研发优势 <span>04投资优势</span><br/>
            05培训优势 <br/>
            ….</p>
          <a routerLink="/cooperation/1" class="link_2">
            查看详情
            <span>→</span>
          </a>
        </div>
      </div>
    </div>
  </main>
</div>
