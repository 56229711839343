<div class="app-agreement">
  <app-banner></app-banner>

  <div class="nav">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-around">
          <div [className]="active === 0 ? 'on' : ''" (click)="onChange(0)">小额免密扣费</div>
          <div [className]="active === 1 ? 'on' : ''" (click)="onChange(1)">分期定额扣费</div>
          <div [className]="active === 2 ? 'on' : ''" (click)="onChange(2)">预售额度扣费</div>
          <div [className]="active === 3 ? 'on' : ''" (click)="onChange(3)">协议免息分期</div>
        </div>
      </div>
    </div>
  </div>

  <div class="title container">
    <h2>协议扣款</h2>
    <h3>AGREEMENT DEDUCTION</h3>
  </div>

  <main class="container">
    <div *ngIf="active === 0">
      <div class="box">
        <h2>小额免密扣费</h2>
        <h3>无需密码，自动按约定扣款，省时省力，安全更有保障。</h3>
        <p>1、操作简单易上手，商家可以按照约定，自动收款，大大减少整个收款环节上的时间、人工等成本。</p>
        <p> 2、一劳永逸，支持自动多批量操作。安全快速到账，明细清楚，减少统计成本。适用自来水公司，电力公司，燃气公司，有限电视</p>
        <p> 公司，餐饮,酒店，健身房，公园等收款和会员自动续费，以及共享充电宝，共享单车，网约车打车费，停车缴费等小额免密自动扣费。</p>
      </div>
      <div class="box">
        <h2>使用方法</h2>
        <h3>一, 先签约, 后自动扣费</h3>
        <div class="row list">
          <div class="col-4">
            <section>
              <h4>01</h4>
              <h5>独立授权</h5>
              <h6>独立授权开通免密和扣款服务</h6>
              <img src="/assets/agreement_img1.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>02</h4>
              <h5>弹框提醒</h5>
              <h6>弹框二次提醒页面</h6>
              <img src="/assets/agreement_img2.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>03</h4>
              <h5>查询详情</h5>
              <h6>小额免密扣款服务查询详情</h6>
              <img src="/assets/agreement_img3.png" alt="协议扣款">
            </section>
          </div>
        </div>
        <h3>二, 支付并签约模式</h3>
        <div class="row list">
          <div class="col-4">
            <section>
              <h4>01</h4>
              <h5>支付签约</h5>
              <h6>支付并签约主页面</h6>
              <img src="/assets/agreement_img4.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>02</h4>
              <h5>签约开关</h5>
              <h6>用户打开签约的开关</h6>
              <img src="/assets/agreement_img5.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>03</h4>
              <h5>弹框提醒</h5>
              <h6>弹框二次提醒页面</h6>
              <img src="/assets/agreement_img2.png" alt="协议扣款">
            </section>
          </div>
        </div>
        <div class="row list">
          <div class="col-4">
            <section>
              <h4>04</h4>
              <h5>确认付款</h5>
              <h6>用户确认付款</h6>
              <img src="/assets/agreement_img7.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>05</h4>
              <h5>查询详情</h5>
              <h6>小额免密扣款查询页面</h6>
              <img src="/assets/agreement_img3.png" alt="协议扣款">
            </section>
          </div>
        </div>
      </div>
      <div class="box">
        <h2>接入指引</h2>
        <p>1、签约: 联系“墨小数据”微信公众号，或提交试用申请，并按要求提交产品签约申请，预计1小时内完成审核。</p>
        <p>2、开发: 接入后，并按如下步骤操作:创建项目->应用管理- ->添加功能- ->上线应用。如有疑问，可联系客服。</p>
      </div>
      <div class="box">
        <h2>使用条件</h2>
        <p>1、账户类型:经过实名认证的企业即可申请使用，不支持个体工商户。</p>
        <p>2、注册资本:注册资本大于或等于500万</p>
        <p>3、开发:接入后，并按如下步骤操作:创建项目->应用管理- ->添加功能- ->上线应用。如有疑问，可联系客服。</p>
        <p>4、经营风险:无</p>
        <p>5、商家需存在真实的线上“app支付”应用场景，才能完成交易收单。</p>
      </div>
      <div class="box">
        <h2>费率</h2>
        <table>
          <tr>
            <th>服务名称</th>
            <th>费率</th>
            <th>服务期限</th>
          </tr>
          <tr>
            <td>单笔费用</td>
            <td>0.6%</td>
            <td>合同约定</td>
          </tr>
        </table>
      </div>
      <div class="box">
        <h2>限额</h2>
        <p>单个商家对于单个用户的扣款额度: 单笔限额 100元，单日限额根据具体业务发生量设定。</p>
      </div>
    </div>
    <div *ngIf="active === 1">
      <div class="box">
        <h2>分期定额扣费</h2>
        <h3>支持多种合作形式，商户对个体或企业，个人对商户、企业及个人。商户定期定额从客户账户划扣 费用，周期、扣款时间可设置，自动划扣。</h3>
        <p>1、协议约定，自动扣费。确保商户能按照约定时间到账，体验有保障的便捷收款体验。</p>
        <p>2、协议合法备案，确保商家于客户之间的资金安全。</p>
        <p>3、操作方便简单，资金流向按照约定自动执行，无需更多人力物力，节省时间。</p>

        <p> 适用保险，公积金，社保，中介服务，教育培训、费物业管理，交通物流服务，房租等,</p>
        <p> 按照预定期限，任- -固定周期从客户账户的扣费。</p>
      </div>
      <div class="box">
        <h2>使用方法</h2>
        <h3>一, 合作双方签订协议, 上传由我司审核真实可靠性</h3>
        <div class="row list">
          <div class="col-4">
            <section>
              <h4>01</h4>
              <h5>签订协议</h5>
              <h6>合作双方签订协议</h6>
              <img src="/assets/agreement_img4.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>02</h4>
              <h5>上传后台</h5>
              <h6>上传服务器后台, 审核</h6>
              <img src="/assets/agreement_img6.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>03</h4>
              <h5>审核结果</h5>
              <h6>3分钟以内, 给出审核结果</h6>
              <img src="/assets/agreement_img8.png" alt="协议扣款">
            </section>
          </div>
        </div>
        <h3>二,按照协议约定内容, 执行自动扣款服务</h3>
        <div class="row list">
          <div class="col-4">
            <section>
              <h4>01</h4>
              <h5>通知提示</h5>
              <h6>扣款前, 通知提示被扣款方</h6>
              <img src="/assets/agreement_img2.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>02</h4>
              <h5>到账提示</h5>
              <h6>收款方到款提示</h6>
              <img src="/assets/agreement_img7.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>03</h4>
              <h5>确认订单</h5>
              <h6>我司电话通知, 确认订单无误</h6>
              <img src="/assets/agreement_img3.png" alt="协议扣款">
            </section>
          </div>
        </div>
      </div>
      <div class="box">
        <h2>服务指引</h2>
        <p>1、联系“墨小数据”微信公众号客服，咨询详细服务流程和要求。</p>
        <p>2、根据小程序模版案例了, 了解更多具体要求。</p>
      </div>
      <div class="box">
        <h2>使用条件</h2>
        <p>1、双方交易协议需符合相关法律规定。</p>
        <p>2、双方明确扣款时间和金额等具体细节。</p>
      </div>
      <div class="box">
        <h2>费率</h2>
        <table>
          <tr>
            <th>服务名称</th>
            <th>费率</th>
            <th>服务期限</th>
          </tr>
          <tr>
            <td>单笔费用</td>
            <td>参考“墨小数据”费率说明</td>
            <td>合同约定</td>
          </tr>
        </table>
      </div>
      <div class="box">
        <h2>限额</h2>
        <p>根据协议约定金额和被扣款服账户银行卡限额决定。</p>
      </div>
    </div>
    <div *ngIf="active === 2">
      <div class="box">
        <h2>预售额度扣费</h2>
        <h3>商家和客户达成合作协议，获取客户预授权额度。在额度范围内，可以任意时间段内完成自动扣款。</h3>
        <p>1、简化商家与客户的沟通次数，大大优化收付款效率。</p>
        <p>2、操作简单，账目管理清晰，多层级审核，保证资金流动安全</p>

        <p>适用企业与个人、企业之间的商务合作。</p>
      </div>
      <div class="box">
        <h2>使用方法</h2>
        <h3>一, 达成合作, 合作双方签订分期付款协议</h3>
        <div class="row list">
          <div class="col-4">
            <section>
              <h4>01</h4>
              <h5>签订协议</h5>
              <h6>签订分期付款协议</h6>
              <img src="/assets/agreement_img4.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>02</h4>
              <h5>上传后台</h5>
              <h6>上传到系统后台审核</h6>
              <img src="/assets/agreement_img6.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>03</h4>
              <h5>审核结果</h5>
              <h6>3分钟以内, 给出审核结果</h6>
              <img src="/assets/agreement_img8.png" alt="协议扣款">
            </section>
          </div>
        </div>
        <h3>二,协议约定期间内, 双方按时自动收款, 自动付款</h3>
        <div class="row list">
          <div class="col-4">
            <section>
              <h4>01</h4>
              <h5>通知提示</h5>
              <h6>扣款前, 通知提示被扣款方</h6>
              <img src="/assets/agreement_img2.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>02</h4>
              <h5>到账提示</h5>
              <h6>收款方到款提示</h6>
              <img src="/assets/agreement_img7.png" alt="协议扣款">
            </section>
          </div>
        </div>
      </div>
      <div class="box">
        <h2>接入指引</h2>
        <p>1、签约: 联系“墨小数据”微信公众号，或提交试用申请，并按要求提交产品签约申请，预计1小时内完成审核。</p>
        <p> 2、开发: 接入后，并按如下步骤操作: 创建项目->应用管理- ->添加功能- ->上线应用 。如有疑问，可联系客服。</p>
      </div>
      <div class="box">
        <h2>使用条件</h2>
        <p>1、收款方为企业, 不支持个人及个体户。</p>
        <p>2、注册资本: 注册资本大于或等于500万</p>
        <p>3、开发: 接入后，并按如下步骤操作: 创建项目->应用管理- ->添加功能- ->上线应用 。如有疑问，可联系客服。</p>
        <p>4、经营风险:无</p>
        <p>5、商家需存在真实的线上“app支付”应用场景，才能完成交易收单。</p>
      </div>
      <div class="box">
        <h2>费率</h2>
        <table>
          <tr>
            <th>服务名称</th>
            <th>费率</th>
            <th>服务期限</th>
          </tr>
          <tr>
            <td>单笔费用</td>
            <td>0.6%</td>
            <td>合同约定</td>
          </tr>
        </table>
      </div>
      <div class="box">
        <h2>限额</h2>
        <p>根据客户授予商家的额度及客户银行卡额度决定。</p>
      </div>
    </div>
    <div *ngIf="active === 3">
      <div class="box">
        <h2>协议免息分期</h2>
        <h3>客户与公司签订协议，客户即可分多期支付款项，确保无息，又能保证公司安全到账。适合大单价
          类公司与个人和企业合作。</h3>
        <p>1、操作简单，安全规范。商家确保收款进度，客户避免由于大额资金支持的风险问题。</p>
        <p>2、商家在确保收款额同时，将大额单价产品转换成多期小单价产品，提高订单交易量。</p>

        <p>适用适合大额单价类公司与个人或企业的合作。</p>
      </div>
      <div class="box">
        <h2>使用方法</h2>
        <h3>一, 合作双方签订协议, 上传由我司审核真实可靠性</h3>
        <div class="row list">
          <div class="col-4">
            <section>
              <h4>01</h4>
              <h5>签订协议</h5>
              <h6>合作双方签订协议</h6>
              <img src="/assets/agreement_img4.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>02</h4>
              <h5>上传后台</h5>
              <h6>上传服务器后台, 审核</h6>
              <img src="/assets/agreement_img6.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>03</h4>
              <h5>审核结果</h5>
              <h6>3分钟以内, 给出审核结果</h6>
              <img src="/assets/agreement_img8.png" alt="协议扣款">
            </section>
          </div>
        </div>
        <h3>二,按照协议约定内容, 执行自动扣款服务</h3>
        <div class="row list">
          <div class="col-4">
            <section>
              <h4>01</h4>
              <h5>通知提示</h5>
              <h6>扣款前, 通知提示被扣款方</h6>
              <img src="/assets/agreement_img2.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>02</h4>
              <h5>到账提示</h5>
              <h6>收款方到款提示</h6>
              <img src="/assets/agreement_img7.png" alt="协议扣款">
            </section>
          </div>
          <div class="col-4">
            <section>
              <h4>03</h4>
              <h5>确认订单</h5>
              <h6>我司电话通知, 确认订单无误</h6>
              <img src="/assets/agreement_img3.png" alt="协议扣款">
            </section>
          </div>
        </div>
      </div>
      <div class="box">
        <h2>服务指引</h2>
        <p>1、联系“墨小数据”微信公众号客服，咨询详细服务流程和要求。</p>
        <p>2、根据小程序模版案例了, 了解更多具体要求。</p>
      </div>
      <div class="box">
        <h2>使用条件</h2>
        <p>1、双方交易协议需符合相关法律规定。</p>
        <p>2、双方明确扣款时间和金额等具体细节。</p>
      </div>
      <div class="box">
        <h2>费率</h2>
        <table>
          <tr>
            <th>服务名称</th>
            <th>费率</th>
            <th>服务期限</th>
          </tr>
          <tr>
            <td>单笔费用</td>
            <td>参考“墨小数据”费率说明</td>
            <td>合同约定</td>
          </tr>
        </table>
      </div>
      <div class="box">
        <h2>限额</h2>
        <p>根据协议约定金额和被扣款服账户银行卡限额决定。</p>
      </div>
    </div>
  </main>
</div>
