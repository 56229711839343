<div class="app-new-detail">
  <app-banner></app-banner>

  <div class="title container">
    <h2>墨小动态</h2>
    <h3>MOXIAODYNAMIC</h3>
  </div>

  <main class="container">
    <div *ngIf="active === 0">
      <h1>墨小成立</h1>
      <ul>
        <li><img src="/assets/newDetail_img1.png" alt="详情">289</li>
        <li><img src="/assets/newDetail_img2.png" alt="详情">89</li>
      </ul>

      <section>
        <p>
          墨小数据科技有限公司成立</p>

        <p>我公司于2018年10月18日注册成立，注册资金500万，致力于以自动扣款为核心，利用大数据、Al技术、身
          份识别技术，为各大中小型企业，以及个体商户提供收款方式的一-站式整体解决方案。</p>

        <p>公司设有项目部、行政部、财务部、技术部、市场开发部等部门，拥有-批专业而经验丰富，专注于 自动代
          扣的开发人员，并聘有国内知名企业核心人员，全力打造了一支坚韧团结，技术雄厚的队伍。同时，专业化
          也是自动扣款必然趋势，是可持续发展的必然要求，当然也是公司前进的保障。我司将一如既往做好各项工
          作，为广大商家的事业发展，贡献我们微薄的力量。</p>

        <p>公司秉承“持续创新、追求完美、诚朴取信、勇担责任"的服务宗旨，坚持着“以质量求生存，以人才求发展,
          以信誉赢市场”的经营理念，以创造生活便捷、回报社会等社会责任为己任，重新定义科技生活。共同创造更
          美好的未来，是我们共同的心愿，墨小数据与您共进。</p>

        <p>沿着时间的隧道穿行，我们拥有团队的凝聚力，那跃动的音符，在市场竞争的狂潮中，是我们永不停息的脚
          步，与智者同行，与勇者为伍，每一双挥动的臂膀都承载着希望，向着既定的目标，每- -声铿锵的号子都在
          唱响成功!
        </p>
      </section>
    </div>
    <div *ngIf="active === 1">
      <h1>开拓垂直邻域，墨小数据旗下子公司有舍成立</h1>
      <ul>
        <li><img src="/assets/newDetail_img1.png" alt="详情">289</li>
        <li><img src="/assets/newDetail_img2.png" alt="详情">89</li>
      </ul>

      <section>
        <p>为进一步适应市场，将聚合支付融入生活，墨小科技于2020年底成立分公司有舍，专注于房屋租赁，解决房东
          和租客之间，实际生活中产生的各种难题。</p>

        <p> 随着城市快节奏化的发展，许多问题渐渐暴露。租房引发的问题,却也成为了广大租户头疼的问题,比如住房条件
          差、房东涨租、中介欺诈等等,使他们常年都游走在搬家的旅程中。同时,作为房东,也有许多难言之处,拖欠房租、
          房屋被损、被邻居投诉。</p>

        <p> 为了更好地服务武汉地区，满足关于租房问题的各种需求，解决潜在社会隐患，助力社会和谐，墨小科技将始终
          保持初心，致力于软件功能开发，潜心务房东，全心全意帮助租客。</p>

        <p>在房东层面，提供更多的资金收付帮助，人身及资金安全保障；在租客方面，维护租客自身的利益，包括杜绝临
          时涨租金现象，无故被解约情况。</p>

        <p>新的目标，新的起点。墨小将秉持以往的优秀传统，砥砺前行。也祝愿有舍的到来，彻底解决关于租赁市场中出
          现的种种矛盾，为武汉这片区域，增添更多色彩。</p>
      </section>
    </div>
  </main>
</div>
