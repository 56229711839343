<header class="public-header">
    <div class="container-fluid">
        <div class="row">
            <div class="logo col-3">
                <img src="/assets/logo.png" alt="logo">
            </div>
            <div class="col-9 d-none d-md-block">
                <div class="d-flex align-items-center justify-content-end">
                    <div class="nav col-9 justify-content-around">
                        <a routerLink="">首页</a>
                        <a routerLink="/about">墨小介绍</a>
                        <a routerLink="/news">墨小动态</a>
                        <a routerLink="/securityNew">安全保障</a>
                        <a routerLink="/cooperationHome">超级合伙人</a>
                    </div>
                    <div class="col-2">
                        <button (click)="login()">注册/登录</button>
                    </div>
                </div>
            </div>
            <div class="col-9 d-flex d-md-none justify-content-end">
                <div class="loginImg">
                    <img src="/assets/login.png" alt="登录" (click)="login()">
                </div>
                <div class="navIcon" (click)="navShow()">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
    <div class="phoneNav" *ngIf="isPhone">
        <div class="main">
            <a routerLink="" (click)="navShow()">首页</a>
            <a routerLink="/about" (click)="navShow()">墨小介绍</a>
            <a routerLink="/news" (click)="navShow()">墨小动态</a>
            <a routerLink="/securityNew" (click)="navShow()">安全保障</a>
            <a routerLink="/cooperationHome" (click)="navShow()">超级合伙人</a>
        </div>
    </div>
</header>