import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent implements OnInit {
  active = 0;

  constructor() {
  }

  ngOnInit(): void {
  }


  onChange(e): void {
    this.active = e;
  }
}
