import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  active = 0;

  constructor() {
  }

  ngOnInit(): void {
  }


  onChange(e): void {
    this.active = e;
  }
}
