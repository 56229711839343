<div class="app-cooperation">
  <app-banner></app-banner>

  <main>
    <div *ngIf="active === 0">
      <div class="title container">
        <h2>合作流程</h2>
        <h3>COOPERATION PROCESS</h3>
      </div>

      <section class="container">
        <div class="row">
          <div class="col">
            <h2>01 机遇合作意向</h2>
            <p>了解基本信息后，留下联系信息，顾问将与客户联系，了解加盟信息及《超级合伙人特许经营白皮书》</p>
            <h2>02 洽谈参观考察</h2>
            <p>初审通过后，填写《超级合伙人投资申请表》后进行考察</p>
            <h2>03 携手明确合作</h2>
            <p>考察后，进一步洽谈具体合作条件，总部扶持原则，明确合作意向</p>
            <h2>04 合作签订合同</h2>
            <p>明确合作后，客户签订《超级合伙人特许经营书》后，方可签订《超级合伙人特许经营合同》。完成签约流程后，总部提供一整套市场开拓资料以供学习</p>
            <h2>05 蓄势，店铺规划</h2>
            <p>提供公司办公地点选址、设计、装修技术支撑服务</p>
            <h2>06 协助施工监督</h2>
            <p>工程监理根据加盟级别审核提供相应的店铺空间设计图纸，并给出装修建议，再根据空间设计图纸、墨小数据VI/SI手册进行店铺装修跟进</p>
            <h2>07 起航开业筹备</h2>
            <p>开始执行，根据需求提供员工招聘建议；根据装修情况和客户实际情况，沟通并确定开业方案</p>
            <h2>08 赋能人员培训</h2>
            <p>总部委派实战经验丰富的客户经理进行服务项目、开业指导、卖点提炼等一系列的全方位培训</p>
            <h2>09 扶持共赢</h2>
            <p>开始运营后，总部持续为超级合伙人提供品牌支持、人才支持、市场规划支持等在内的12大支持，为超级合伙人保驾护航！</p>
            <h2>10 运营支持</h2>
            <p>开业后，协助超级合伙人处理经营过程中的问题，实现公司运营能力提升。</p>
          </div>
        </div>
      </section>
    </div>
    <div *ngIf="active === 1">
      <div class="title container">
        <h2>墨小数据政策与扶持</h2>
        <h3>POLICY AND SUPPORT</h3>
      </div>

      <section class="container">
        <div class="row">
          <div class="col">
            <h2>总部权威扶持:</h2>
            <h2>01 规划优势</h2>
            <p>墨小数据拥有一支强大的营销团队，根据每个合伙人的实际情况，制定一对一的发展策略。</p>
            <h2>02 营销优势</h2>
            <p>总部常年营销指导，结合合伙人实际经营情况，不定期举行营销活动，帮助超级合伙人，迅速扩大该片区影响力。</p>
            <h2>03 研发优势</h2>
            <p>总公司提供给超级合伙人稳定的技术开发支持，保证产品的更新换代，并保证产品及时响应用户需求。</p>
            <h2>04 投资优势</h2>
            <p>墨小数据受众广泛，支持多场景应用。具备投资小、风险低、安全可靠，方便快捷、用户粘性高等多种优质属性。在市场上口碑较好，极容易做推广宣传，而且利润长期收益有保障，客户续
              费长期返佣，每个客户的交易都可以拿到分佣+分润，市场回报前景巨大。</p>
            <h2>05 培训优势</h2>
            <p>墨小数据拥有独立的培训团队，和专业的培训老师，在公司开业之前，对超级合伙人，及员工进行全套、系统的培训与考核，直到完全掌握。正常运营后，不定期推出新的培训内容进行进修
              学习。</p>
            <h2>06 品牌优势</h2>
            <p>公司会定期邀请超级合伙人参加大型品牌宣传会，对产品进行整体、系统的品牌宣传策划及推广。</p>
            <h2>07 保护优势</h2>
            <p>墨小为保护每位超级合伙人的自身利益，实行区域保护制度，最大化扩展每位超级合伙人的利益区域，实现互创共赢。</p>
            <h2>08 技术支持</h2>
            <p>为合伙人提供专业三级分销工具，提供数据分析，实时反馈，问题分析解决。</p>
            <h2>总部限时鼓励政策: <br>
              为进一步 扩大市场，迅速提高品牌影响力，墨小数据科技决定实行限时特殊扶持，政策如下：</h2>
            <p>
              1、超级合伙人申请费用立减2万元，现只需3.5万元。</p>
            <p>2、鼓励广大有志之士加入，降低门槛。可为超级合伙人提供无息多期支付方式，减小资金压力。经营前期，墨小数据保障合伙人资金运转顺畅。签订品牌保护协议后，保证金形式将以实际发
              生业绩，按照比例抽取。同时，按照业绩发生量，可提前解冻保障金。
            </p>
          </div>
        </div>
      </section>
    </div>
  </main>
</div>
