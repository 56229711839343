import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isPhone: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  login(): void {
    window.location.href = 'https://admin.moxiaoshuju.com/#/login/2';
  }

  navShow(): void {
    this.isPhone = !this.isPhone;
  }
}
