<div class="app-advantage">
  <app-banner></app-banner>

  <div class="nav">
    <div class="container">
      <div class="row">
        <div class="col">
          <div [className]="active === 0 ? 'on' : ''" (click)="onChange(0)">平台服务</div>
          <div [className]="active === 1 ? 'on' : ''" (click)="onChange(1)">api接入</div>
          <div [className]="active === 2 ? 'on' : ''" (click)="onChange(2)">系统定制</div>
        </div>
      </div>
    </div>
  </div>

  <main class="container">
    <div *ngIf="active === 0">
      <h2>01 提供专业资源扩展、整合</h2>
      <p>打通各类型商家信息壁垒，为商家提供资源互补，打造全方位，多维度生态，促成贸易合作升级，增加企业自身竞争优势，达成1+1>2的效果 。
      </p>
      <h2>02 24小时专业维护团队</h2>
      <p>拥有经验丰富的运营团队，为商家后期使用保驾护航。3分钟内信息反馈，10分钟给出解决方案，并且提供上门扶助，确保用户的体验及资金安全。</p>
      <h2>03 资深行业策划</h2>
      <p>1、为商家打造专业的运营、活动、品牌策划建议方案,树立自身影响力，提高议价能权。</p>
      <p>2、给出最有效的扣款场景方案，合理运用四种收款模式，既提高商营业效率,又能提高商家营业额。</p>
      <h2>04 资深行业策划</h2>
      <p>可以有效降低商家的营销成本。通过平台发布功能，帮助商家大大降低在传统营销中:人员促销、营业推广、参加广交会等活动的不必要成本。</p>
      <h2>05 提供大数据身份认证</h2>
      <p>帮助涉及到网络交易的商家，避免网络交易虚拟性的弊端，规避在交易过程中，容易发生的欺诈问题。</p>
    </div>
    <div *ngIf="active === 1">
      <h2>01 专业技术团队</h2>
      <p>拥有专业技术开发团队，具备核心运维能力。适用于多种二次语言开发，效果好，服务完善，并且可为商家提供接口相关技术支持等-站式的服务。</p>
      <h2>02 多平台终端接入、多语言支持</h2>
      <p>覆盖IOS、Android、Windows、 Mac多个平台终端接入，支持全业务功能。同时，针对不同商家，提供多种主流语言选择。</p>
      <h2>03 权威的数据源</h2>
      <p>对接权威的数据源，如:身份证实名认证对接公安数据源，手机号实名认证对接运营商数据源，使得认证结果更具备保障性。</p>
      <h2>04 产品种类齐全</h2>
      <p>为有需求提供各类实名认证产品和ocr识别功能，包含产品种类齐全，可根据不同用户满足不同的需求。</p>
    </div>
    <div *ngIf="active === 2">
      <h2>莫小数据为以下技术短板商家，提供系统定制服。减少开支，轻松有用专属扣费系统，高度契合自身消费场景。</h2>
      <h2>01 技术能力不足</h2>
      <p>企业不具备系统开发及维护能力，业务没有系统化，运作效率低下</p>
      <h2>02 管理能力不足</h2>
      <p>企业管理依托纸质化办公，流程漫长，管理人员过多，企业扩张瓶颈明显</p>
      <h2>03 风控能力不足</h2>
      <p>企业的风控能力不足，不具备资金风险识别能力，没有风险控制手段。</p>
      <h2>04 支付处理能力不足</h2>
      <p>依托现金及银行处理日常资金无法适应移动互联网时代的支付现状</p>
    </div>
  </main>
</div>
