<div class="app-release">
  <app-banner></app-banner>

  <div class="title container">
    <h2>平台发布</h2>
    <h3>PLATFORM RELEASE</h3>
  </div>

  <div class="container list">
    <div class="row">
      <div class="col-md-4">
        <h2>信息发布平台</h2>
        <p>开源产品信息发布平台，打破信息壁垒给使用者提供自由发布产品信息的渠道,助理企业或个人推出更有效的产品展示。</p>
        <img src="/assets/release_img1.png" alt="平台发布">
      </div>
      <div class="col-md-4">
        <h2>营造产品生态圈</h2>
        <p>营造产品生态圈，互创共荣, 打通产品上下游，挖掘产品供需关系，营造长远生态式运营，做到互创共荣</p>
        <img src="/assets/release_img2.png" alt="平台发布">
      </div>
      <div class="col-md-4">
        <h2>低成本高转化</h2>
        <p>降低营销成本，提高转化率。帮助企业宣传活动等信息，高品牌曝光量,增加自身知名度。</p>
        <img src="/assets/release_img3.png" alt="平台发布">
      </div>
    </div>
  </div>

  <div class="title container">
    <h2>使用方法</h2>
    <h3>USAGE METHOD</h3>
  </div>

  <div class="container">
    <div class="methods">
      <section>
        <div class="img">
          <img src="/assets/release_img4.png" alt="平台发布">
        </div>
        <h3>1.点击登录签约</h3>
      </section>
      <section>
        <div class="img">
          <img src="/assets/release_img5.png" alt="平台发布">
        </div>
        <h3>2.点击加号,填发布内容</h3>
      </section>
      <section>
        <div class="img">
          <img src="/assets/release_img6.png" alt="平台发布">
        </div>
        <h3>3.选择行业</h3>
      </section>
      <section>
        <div class="img">
          <img src="/assets/release_img7.png" alt="平台发布">
        </div>
        <h3>4.选择地区</h3>
      </section>
      <section>
        <div class="img">
          <img src="/assets/release_img8.png" alt="平台发布">
        </div>
        <h3>5.填写标题</h3>
      </section>
      <section>
        <div class="img">
          <img src="/assets/release_img9.png" alt="平台发布">
        </div>
        <h3>6.填写产品内容</h3>
      </section>
      <section>
        <div class="img">
          <img src="/assets/release_img10.png" alt="平台发布">
        </div>
        <h3>7.选择图片上传</h3>
      </section>
      <section>
        <div class="img">
          <img src="/assets/release_img11.png" alt="平台发布">
        </div>
        <h3>8.点击上传</h3>
      </section>
      <section>
        <div class="img">
          <img src="/assets/release_img12.png" alt="平台发布">
        </div>
        <h3>9.点击我的产品, 查看发布内容</h3>
      </section>
    </div>
  </div>
</div>
