<div class="app-service-object">
  <app-banner></app-banner>

  <div class="title container">
    <h2>服务对象</h2>
    <h3>SERVICE OBJECT</h3>
  </div>

  <div class="container">
    <div class="row">
      <section class="col-md-3">
        <ul>
          <li [className]="active === 0 ? 'on' : ''" (click)="onChange(0)">房东</li>
          <li [className]="active === 1 ? 'on' : ''" (click)="onChange(1)">教育运营经理</li>
          <li [className]="active === 2 ? 'on' : ''" (click)="onChange(2)">信贷专员</li>
          <li [className]="active === 3 ? 'on' : ''" (click)="onChange(3)">中介客服经理</li>
          <li [className]="active === 4 ? 'on' : ''" (click)="onChange(4)">打车软件开发</li>
          <li [className]="active === 5 ? 'on' : ''" (click)="onChange(5)">健身会员经理</li>
          <li [className]="active === 6 ? 'on' : ''" (click)="onChange(6)">招商经理</li>
        </ul>
      </section>

      <main class="col-md-9">
        <div *ngIf="active === 0">
          <h2>房东</h2>
          <h3>适合功能:</h3>
          <p>分期定额扣款</p>
          <h3>使用方法:</h3>
          <p>1、房租一键到账，不在担心拖欠问题</p>
          <p>2、统-渠道，自动收租。拥有强大记账功能，每个月的租金明细随时查询，避免漏收、统计错误等现象。</p>
        </div>
        <div *ngIf="active === 1">
          <h2>教育运营经理</h2>
          <h3>适合功能:</h3>
          <p>小额免密扣费</p>
          <h3>使用方法:</h3>
          <p>1、将推广活动的优惠方案转换成续费会员得好处，增加用户粘性的同时，大大降低运营成本。</p>
          <p>2、丰富营销获客手段。采用独立的资金管理系统和会员管理系统相结合，推出更加契合自身的活动方案。</p>
        </div>
        <div *ngIf="active === 2">
          <h2>信贷专员</h2>
          <h3>适合功能:</h3>
          <p>预售额度扣款，分期定额扣款</p>
          <h3>使用方法:</h3>
          <p>1、配合技术人员直接接入，一键实现会员自动扣费,结算自动扣费等功能节省大量开发、对接时间。</p>
          <p>2、24小时专人，维护省时省力，安全有保障。</p>
        </div>
        <div *ngIf="active === 3">
          <h2>打车软件开发</h2>
          <h3>适合功能:</h3>
          <p>分期定额扣款、预售额度扣款、小额免密扣款</p>
          <h3>使用方法:</h3>
          <p>1、配合技术人员直接接入，一键实现会员自动扣费,结算自动扣费等功能节省大量开发、对接时间。</p>
          <p>2、24小时专人，维护省时省力，安全有保障。</p>
        </div>
        <div *ngIf="active === 4">
          <h2>打车软件开发</h2>
          <h3>适合功能:</h3>
          <p>分期定额扣款</p>
          <h3>使用方法:</h3>
          <p>1、大批量收取客户中介服务费，适合短期或者长期服务，自动收款，无需挨个通知办理复杂手续。</p>
          <p>2、资金统计明确，应收、未收等数据可实时查看，让工作化繁为简。</p>
        </div>
        <div *ngIf="active === 5">
          <h2>健身会员经理</h2>
          <h3>适合功能:</h3>
          <p>分期定额扣款、小额免密扣款</p>
          <h3>使用方法:</h3>
          <p>1、自动扣款、续费增值会员，无需二次营销</p>
          <p>2、自动定期收取客户办卡费,方便快捷。</p>
        </div>
        <div *ngIf="active === 6">
          <h2>招商经理</h2>
          <h3>适合功能:</h3>
          <p>分期定额扣款、协议免息扣款</p>
          <h3>使用方法:</h3>
          <p>1、降低加盟费门]槛要求，提高签约率</p>
          <p>2、适合阶段性费用收取，避免因为款项原因，导致加盟合作项目的沟通难度。</p>
        </div>
      </main>
    </div>
  </div>
</div>
