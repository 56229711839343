<div class="app-security-new">
    <app-banner [title]="'以用户为中心'" [content]="'我们立志为用户打造一站式收款解决方案'"></app-banner>

    <div class="nav">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div routerLink="/security/0">账户安全</div>
                    <div routerLink="/security/1">系统安全</div>
                    <div routerLink="/security/2">风险控制</div>
                </div>
            </div>
        </div>
    </div>

    <div class="title container">
        <h2>为用户打造一站式收款解决方案</h2>
    </div>

    <div class="container map">
        <img src="/assets/security-new_img1.png" alt="解决方案">
    </div>

    <div class="advantage">
        <header class="title">
            <h2>优势</h2>
            <h3>ADVANTAGE</h3>
        </header>
        <main class="container">
            <div class="row">
                <div class="col">
                    <img src="/assets/security-new_img2.png" alt="优势">
                    <h4>用户覆盖面广</h4>
                    <p>支持国内200多家银行 全面满足用户需要</p>
                </div>
                <div class="col">
                    <img src="/assets/security-new_img3.png" alt="优势">
                    <h4>省钱省时省力</h4>
                    <p>合作商家无需与多家银行接 入，为商户缩减对接成本
                    </p>
                </div>
                <div class="col">
                    <img src="/assets/security-new_img4.png" alt="优势">
                    <h4>企业、商户轻松接入</h4>
                    <p>标准的接入说明，多种接入 样例，降低对接难度
                    </p>
                </div>
                <div class="col">
                    <img src="/assets/security-new_img5.png" alt="优势">
                    <h4>交易管理方便</h4>
                    <p>墨小提供完整清晰的交易 记录，方便财务对账
                    </p>
                </div>
                <div class="col">
                    <img src="/assets/security-new_img6.png" alt="优势">
                    <h4>多重安全保障</h4>
                    <p>领先技术、严密流程 构建安全放心的平台
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <img src="/assets/security-new_img7.png" alt="优势">
                    <h4>统一的风险控制</h4>
                    <p>资质审核，实名认证 7*24小时风控监测
                    </p>
                </div>
                <div class="col">
                    <img src="/assets/security-new_img8.png" alt="优势">
                    <h4>终端应用范围广</h4>
                    <p>墨小覆盖手机客户端 、PC客户 端等不同终端类型
                    </p>
                </div>
                <div class="col">
                    <img src="/assets/security-new_img9.png" alt="优势">
                    <h4>多元的解决方案</h4>
                    <p>满足用户的不同需求
                    </p>
                </div>
                <div class="col">
                    <img src="/assets/security-new_img10.png" alt="优势">
                    <h4>全国费率统一</h4>
                    <p>费率透明全国统一
                    </p>
                </div>
                <div class="col">
                    <img src="/assets/security-new_img11.png" alt="优势">
                    <h4>VIP客服制度</h4>
                    <p>超贴心的专业服务
                    </p>
                </div>
            </div>
        </main>
    </div>

    <div class="container admin">
        <div class="item item_1">
            <div class="row">
                <div class="col">
                    <h2>灵活自助</h2>
                    <p>墨小提供365天7*24小时签约服务支持，统一T+1结算， 结算方式多样可选，缩短交易结算周期，加速资金回笼， 提高企业资金利用率
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-end">
                    <img src="/assets/security-new_img12.png" alt="灵活自助">
                </div>
            </div>
        </div>
        <div class="item item_2">
            <div class="row">
                <div class="col text">
                    <h2>全自助管理平台</h2>
                    <p>上千家企业、商户首选平台，行业覆盖面广，墨小数据平 台强大的交易管理系统使企业、商户对交易管理、订单查 询等每一个业务环节自助掌控，提供专业对账单及报表服 务，方便财务进行账务核对和管理。
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <img src="/assets/security-new_img13.png" alt="全自助管理平台">
                </div>
            </div>
        </div>
    </div>
</div>