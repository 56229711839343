import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-security-new',
  templateUrl: './security-new.component.html',
  styleUrls: ['./security-new.component.scss']
})
export class SecurityNewComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
