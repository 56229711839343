import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AboutComponent} from './about/about.component';
import {AgreementComponent} from './agreement/agreement.component';
import {ReleaseComponent} from './release/release.component';
import {ServiceSectorComponent} from './service-sector/service-sector.component';
import {AdvantageComponent} from './advantage/advantage.component';
import {ServiceObjectComponent} from './service-object/service-object.component';
import {SecurityHomeComponent} from './security-home/security-home.component';
import {SecurityNewComponent} from './security-new/security-new.component';
import {SecurityComponent} from './security/security.component';
import {CooperationHomeComponent} from './cooperation-home/cooperation-home.component';
import {CooperationComponent} from './cooperation/cooperation.component';
import {NewsComponent} from './news/news.component';
import {NewDetailComponent} from './new-detail/new-detail.component';
import {HistoryComponent} from './history/history.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'index', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'agreement', component: AgreementComponent},
  {path: 'release', component: ReleaseComponent},
  {path: 'serviceSector/:id', component: ServiceSectorComponent},
  {path: 'advantage/:id', component: AdvantageComponent},
  {path: 'serviceObject/:id', component: ServiceObjectComponent},
  {path: 'securityHome', component: SecurityHomeComponent},
  {path: 'securityNew', component: SecurityNewComponent},
  {path: 'security/:id', component: SecurityComponent},
  {path: 'cooperationHome', component: CooperationHomeComponent},
  {path: 'cooperation/:id', component: CooperationComponent},
  {path: 'news', component: NewsComponent},
  {path: 'newDetail/:id', component: NewDetailComponent},
  {path: 'history/:id', component: HistoryComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
