import {Component, OnInit} from '@angular/core';
import {HttpService} from '../http.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  name = '';
  phone = '';
  enterpriseName = '';
  verificationCode = '';
  error = '';
  key = '';
  codeText = '获取验证码';
  codeTime = 0;

  constructor(private http: HttpService) {
  }

  ngOnInit(): void {
  }

  code(): void {
    if (this.codeTime > 0) {
      return;
    }
    if (this.phone.length !== 11) {
      this.error = '请输入手机号';
      return;
    }
    const data = {
      key: 'enterprises',
      phone: this.phone
    };
    this.http.code(data)
      .subscribe((res) => {
        if (res.success) {
          this.error = '';
          this.key = res.data.key;

          this.codeTime = 60;
          const Interval = setInterval(() => {
            if (this.codeTime <= 0) {
              this.codeText = `获取验证码`;
              clearInterval(Interval);
            } else {
              this.codeTime = this.codeTime - 1;
              this.codeText = `${this.codeTime}s 后重试`;
            }
          }, 1000);
        }
      });
  }

  save(): void {
    if (this.verificationCode === '') {
      this.error = '请输入验证码';
      return;
    }
    if (this.name === '') {
      this.error = '请输入姓名';
      return;
    }
    if (this.phone.length !== 11) {
      this.error = '请输入手机号';
      return;
    }
    if (this.enterpriseName === '') {
      this.error = '请输入企业名称';
      return;
    }

    const data = {
      companyname: this.enterpriseName,
      phone: this.phone,
      link: this.name,
      key: this.key,
      code: this.verificationCode,
    };
    this.http.saveCompanyEnter(data)
      .subscribe((res) => {
        if (res.success) {
          this.error = '提交成功';
          this.name = '';
          this.phone = '';
          this.enterpriseName = '';
          this.verificationCode = '';
          this.key = '';
        } else {
          this.error = res.message;
        }
      });
  }
}
