<div class="app-history">
  <app-banner></app-banner>

  <div class="nav">
    <div class="container">
      <div class="row">
        <div class="col">
          <div [className]="active === 0 ? 'on' : ''" (click)="onChange(0)">2.0.1版发布</div>
          <div [className]="active === 1 ? 'on' : ''" (click)="onChange(1)"> 1.8.3版发布</div>
          <div [className]="active === 2 ? 'on' : ''" (click)="onChange(2)">1.5.1版发布</div>
        </div>
      </div>
    </div>
  </div>

  <div class="title container">
    <h2>墨小动态</h2>
    <h3>MOXIAODYNAMIC</h3>
  </div>

  <main class="container">
    <div *ngIf="active === 0">
      <h1>墨小数据信贷员2.0.1版发布</h1>
      <h2>优化:</h2>
      <p>优化性能，尤其是打开应用后的画面流畅程度, 重构发布功能 (增加稳定性，防止异常操作引起主进程崩溃)</p>
      <h2>修复:</h2>
      <p>发布功能下拉菜单宽度改为和画面宽度保持一-致</p>
      <p>修复: 微信公众号进行登录的一处崩溃</p>
      <p>修复: 部分网银支付完成后跳转到空白页的问题</p>
      <p>修改: 重构过程中的各种兼容性问题</p>
    </div>
    <div *ngIf="active === 1">
      <h1>墨小数据信贷员1.8.3版发布</h1>
      <h2>优化:</h2>
      <p>对性能进行优化, 打开页面内容的加载速度更快</p>
      <h2>修复:</h2>
      <p>修复发布后置顶等功能设置的产品顺序问题</p>
    </div>
    <div *ngIf="active === 2">
      <h1>墨小数据企业1.5.1版发布</h1>
      <h2>优化:</h2>
      <p>升级架构，优化企业及员工使用的流畅程度</p>
      <p>优化企业员工通道saas相关信息记录流程</p>
      <h2>修复:</h2>
      <p>修复点击登录设置中的崩溃的问题</p>
      <p>修复win8安卓下设置页面加载问题</p>
      <p>修复从聚合支付列表中填入数据信息出现的卡顿，避免操作重复与错误</p>
      <p>修复部分IOS用户部分功能卡顿的问题</p>
    </div>
  </main>
</div>
