<div class="app-security-home">
  <app-banner></app-banner>

  <div class="title container">
    <h2>安全保障</h2>
    <h3>SAFETY GUARANTEE</h3>
  </div>

  <main>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-9">
          <h2>账户安全</h2>
          <p>01账户密码保护 02账户登录保护 03设置安全密码 04升级安全保护问题</p>
          <a routerLink="/security/0">
            查看详情
            <span>→</span>
          </a>
        </div>
        <div class="col-3">
          <img src="/assets/security_img1.png" alt="安全保障">
        </div>
      </div>
    </div>
    <section>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-9">
            <h2>系统安全</h2>
            <p>01商户账号认证安全 02数字证书 03电脑系统安全</p>
            <a routerLink="/security/1">
              查看详情
              <span>→</span>
            </a>
          </div>
          <div class="col-3">
            <img src="/assets/security_img2.png" alt="安全保障">
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-9">
          <h2>风险控制</h2>
          <p>01企业, 商户安全管理体系 02防范伪造欺诈 03防钓鱼, 防盗卡系统</p>
          <a routerLink="/security/2">
            查看详情
            <span>→</span>
          </a>
        </div>
        <div class="col-3">
          <img src="/assets/security_img3.png" alt="安全保障">
        </div>
      </div>
    </div>
  </main>
</div>
