<div class="app-security">
  <app-banner></app-banner>

  <div class="nav">
    <div class="container">
      <div class="row">
        <div class="col">
          <div [className]="active === 0 ? 'on' : ''" (click)="onChange(0)">账户安全</div>
          <div [className]="active === 1 ? 'on' : ''" (click)="onChange(1)">系统安全</div>
          <div [className]="active === 2 ? 'on' : ''" (click)="onChange(2)">风险控制</div>
        </div>
      </div>
    </div>
  </div>

  <main class="container">
    <div *ngIf="active === 0">
      <h2>01 账户密码保护</h2>
      <p>为防止商户账号密码被木马程序或病毒窃取，账户密码在SSL数据加密传输基础上对商户及持卡人的关键信息进行再次的复杂加密，可以有效防止木马程序截取商户及持卡人数据。
      </p>
      <h2>02 账户登录保护</h2>
      <p>商户账户登录状态不允许记忆，以防止账号及密码因他人使用同台电脑被盗取。如遇登录错误时，请勿返回详细的错误类型页面，防止他人对商户账号及密码的反复尝试猜测获取。</p>
      <h2>03 设置安全密码</h2>
      <p>企业、商户账户有两个密码，分别是：登录密码和交易密码。登录密码和交易密码一定要分别设置，不能为了方便设置成同样一个密码。</p>
      <p>1、不要使用电话号码、生日、名字等容易被破解的字符作为密码，建议使用复杂字符组合作为密码，提高密码安全强度。</p>
      <p>2、不定期更换密码，并妥善保管，以防泄漏。</p>
      <p>3、不要在网吧、图书馆等公共场所进行网上交易，以防他人盗用、窃取账号和密码。</p>
      <p>4、不要与其他网站使用同一密码，以防同时失窃。</p>
      <h2>04 升级安全保护问题</h2>
      <p>安全保护问题将作为您通用的身份校验，可用于找回登录密码、找回交易密码、申请证书、导入证书等。一定要个性化设置，不要很容易被人猜出答案。安全保护问题能进一步加强商户账户
        的安全性。
      </p>
    </div>
    <div *ngIf="active === 1">
      <h2>01 商户账号认证安全</h2>
      <p>为了保证企业、商户交易账号唯一性，接收各交易环节的交易通知都针对商户开通的唯一交易账号。该账号适用于通过墨小数据平台完成任何类型业务的交易认证。在企业商户登录和使用过
        程中，通过全球最先进的安全防护体系以防止企业、商户及持卡人数据在交易和网络传输过程中被窃听和截取。同时还启用数据加密技术，即使外部入侵者获取数据，数据也不可读。</p>
      <h2>02 数字证书</h2>
      <p>数字证书采用SSL加密技术，可以对网络信息加密解密、签名和验证，确保机密性、完整性。</p>
      <h2>03 电脑系统安全</h2>
      <p>定期下载安装最新的操作系统和浏览器安全程序或补丁</p>
      <p>1、计算机中的hosts文件修改为只读。</p>
      <p>2、支付交易的电脑不作为资料、文件共享等类型的服务器。</p>
      <p>3、安装专业的杀毒软件。</p>
      <p>4、安装网络防火墙，防范病毒与木马程序入侵。</p>
      <p>5、定期更新杀毒软件。</p>
      <p>6、下载文件后，打开之前请先进行病毒扫描。</p>
      <p>7、不要开启不明来历的网址、网页链接、电子邮件。</p>
    </div>
    <div *ngIf="active === 2">
      <h2>01 企业、商户安全管理体系</h2>
      <p>为了维护普通消费者利益，墨小对想要加入墨小的特约商户有着严格的签约审核标准：</p>
      <p>1、按照相关规定及要求，所有企业商户签约时均须向墨小公司递交其公司营业执照、组织机构代码证、税务登记证、开户许可证、法定代表人身份证复印件等审核材料，由墨小公司风控专员
      <p>根据既定的企业商户签约审核标准或流程对企业商户的资质等进行审核验证。</p>
      <p>2、对于不同行业的企业、商户，墨小已制定了最少注册年限、最低注册资本等相应严格的企业、商户准入限制性标准。只有达到该标准的企业、商户，方有机会成为墨小的签约企业、商户。</p>
      <p>3、墨小风控部门已成立了正式的企业、商户资质复查小组，不定期对已签约企业、商户的经营规模、资信水平进行抽样复核，如发现企业、商户任何违规或涉嫌违规操作，立时取消签约资格。</p>
      <h2>02 防范伪造欺诈</h2>
      <p>1、妥善保管好账户注册资料，特别是账户密码和手机验证码：不要在任何时候以任何方式向别人泄露自己的密码和手机验证码：墨小绝对不会以任何名义、任何方式向用户索取密码和手机验
        证码；墨小联系用户一律使用公司固定电话，对外电话显示为全国统一客服热线400-676-0009，任何时候都不会使用手机联系用户。如果有人知道了您的墨小账户密码，请立即更改并联系
        我们；办理修改交易密码手续。</p>
      <p>2、那些假冒网站会试图模仿墨小网址的样式和页面风格来获得您的密码以及对您账户的访问权限。如果网址中斜杠前包含其他字符，如“ -、_”那么该网站绝不是墨小的官方网站。</p>
      <h2>03 防钓鱼、防盗卡体系</h2>
      <p>钓鱼网站指的是那些通过仿冒真实企业、商户的网站地址或页面显示内容，骗取消费者进行消费操作，以此窃取消费者用户账号密码或银行账号密码的非法网站。墨小凭借其强大的多重安全
        保障系统，对账户信息、交易信息、签名、信息传输等皆进行128位SSL加密，通过采用先进的风控技术手段，有效遏制钓鱼、盗卡欺诈。</p>
      <h2>04 反洗钱监控机制</h2>
      <p>墨小公司已根据中国人民银行发布的《支付机构反洗钱和反恐怖融资管理办法》中的相关要求，建立了完整的反洗钱内部控制制度，对企业、商户可疑交易进行监控，防止商户通过墨小公司
        进行任何非法交易。如果您发现网上交易账户出现问题或异常时，请在第一时间拨打墨小公司的客服热线：400-676-0009。</p>
    </div>
  </main>
</div>
