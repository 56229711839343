<!--顶部header-->
<app-header></app-header>

<!--内容区域-->
<router-outlet></router-outlet>

<!--表单-->
<app-form></app-form>

<!--底部版权信息-->
<app-footer></app-footer>
