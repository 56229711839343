import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cooperation-home',
  templateUrl: './cooperation-home.component.html',
  styleUrls: ['./cooperation-home.component.scss']
})
export class CooperationHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
