import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FormComponent } from './form/form.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { AgreementComponent } from './agreement/agreement.component';
import { ReleaseComponent } from './release/release.component';
import { ServiceSectorComponent } from './service-sector/service-sector.component';
import { AdvantageComponent } from './advantage/advantage.component';
import { ServiceObjectComponent } from './service-object/service-object.component';
import { SecurityComponent } from './security/security.component';
import { SecurityHomeComponent } from './security-home/security-home.component';
import { CooperationComponent } from './cooperation/cooperation.component';
import { CooperationHomeComponent } from './cooperation-home/cooperation-home.component';
import {FormsModule} from '@angular/forms';
import { NewsComponent } from './news/news.component';
import { NewDetailComponent } from './new-detail/new-detail.component';
import { HistoryComponent } from './history/history.component';
import { BannerComponent } from './banner/banner.component';
import { SecurityNewComponent } from './security-new/security-new.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FormComponent,
    FooterComponent,
    AboutComponent,
    AgreementComponent,
    ReleaseComponent,
    ServiceSectorComponent,
    AdvantageComponent,
    ServiceObjectComponent,
    SecurityComponent,
    SecurityHomeComponent,
    CooperationComponent,
    CooperationHomeComponent,
    NewsComponent,
    NewDetailComponent,
    HistoryComponent,
    BannerComponent,
    SecurityNewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
